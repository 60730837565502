<template>
	<div class="bigDiv">
		<div>
			<!-- 面包屑导航 -->
			<el-breadcrumb separator-class="el-icon-arrow-right">
			  <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
			  <el-breadcrumb-item>数据统计</el-breadcrumb-item>
			  <el-breadcrumb-item>销售统计</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div style="margin-top:20px">
			<el-card>
				<el-row :gutter="10">
					<el-col :span="8">
						<el-input placeholder="请输入需要查询的门店ID" v-model="queryInfo.query" class="input-with-select" clearable @clear="seachVal">
						</el-input>
					</el-col>
					<div class="block">
					        <el-date-picker
							  v-model="value"
							  type="daterange"
							  range-separator="至"
							  start-placeholder="开始日期"
							  end-placeholder="结束日期"
							 :default-time="['00:00:00', '23:59:59']"
							 value-format="yyyy-MM-dd HH:mm:ss">
							</el-date-picker>
						<el-button slot="append" icon="el-icon-search" @click="ListArr()"></el-button>
					  </div>
				</el-row>
				<el-table
				      :data="ArrList"
					  border
				      style="width: 100%"
					  :summary-method="getSummaries"
					  show-summary>
					  <el-table-column
						type="index"
						label="序号"
						width="80">
					  </el-table-column>
				      <el-table-column
				        prop="SID"
				        label="门店ID"
				        width="100">
				      </el-table-column>
					  <el-table-column
					    prop="BILLID"
					    label="销售单号"
					    width="160">
					  </el-table-column>
				      <el-table-column
				        prop="MATTCODE"
				        label="产品编码"
				        width="120">
				      </el-table-column>
				      <el-table-column
				        prop="MATTNAME"
				        label="产品名称"
						width="330">
				      </el-table-column>
					  <el-table-column
					    prop="JSTYPE"
					    label="结算方式"
					  	width="100">
					  </el-table-column>
					  <el-table-column
					    prop="XSNUM"
					    label="销售数量"
						width="100">
					  </el-table-column>
					  <el-table-column
					    prop="XSMONEY"
					    label="商品总价"
						width="100">
					  </el-table-column>
					  <el-table-column
					    prop="YHMONEY"
					    label="优惠"
						width="100">
					  </el-table-column>
					  <el-table-column
					    prop="SSMONEY"
					    label="实收"
						width="100">
					  </el-table-column>
					  <el-table-column
					    prop="FDATE"
					    label="交易时间"
						width="160">
					  </el-table-column>
				    </el-table>
					<!-- 分页功能 -->
					<div style="margin-top: 20px;">
						<el-pagination
						  @size-change="SizeChange"
						  @current-change="CurrentChange"
						  :current-page="queryInfo.pagenum"
						  :page-sizes="[10, 30, 50]"
						  :page-size="queryInfo.pagesize"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="total">
						</el-pagination>
					</div>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			const currentDate = this.getDate({
				format: true
			});
			return{
				queryInfo:{
					query:'',
					pagenum:1,
					pagesize:10
				},
				value: [currentDate + ' 00:00:00',currentDate + ' 23:59:59'],
				ArrList: [],
				total:0
			}
		},
		methods:{
			//清空input
			seachVal(){
				this.ListArr()
			},
			//获取列表
			ListArr(){
				// console.log(this.value)
				this.ArrList = []
				let parm = new URLSearchParams()
				let getUserSession = JSON.parse(sessionStorage.getItem('userSession'));
				// console.log(getUserSession.ID);
				parm.append('query',this.queryInfo.query);
				parm.append('TOKEN',getUserSession.token);
				parm.append('STRDATE',this.value[0]);
				parm.append('ENDDATE',this.value[1]);
				this.$axios.post('/xsrptlist.php',parm)
				.then(res=>{
					// console.log(res)
					if (res.data.code == 200){
						this.ArrList = res.data.data
					}
				})
			},
			// 每页数据条数发生改变触发
			SizeChange(newsize){
				this.queryInfo.pagesize = newsize;
				this.getUser();
			},
			// 当前页码发生改变触发
			CurrentChange(newnum){
				this.queryInfo.pagenum = newnum;
				this.getUser();
			},
			getSummaries(param) {
				const { columns, data } = param;
				const sums = [];
				columns.forEach((column, index) => {
				  if (index === 0) {
					sums[index] = '合计';
					return;
				  }
				  if (index === 1 || index === 2 || index === 3 || index === 4 || index === 5 || index === 10) {
					return;
				  }
				  const values = data.map(item => Number(item[column.property]));
				  if (!values.every(value => isNaN(value))) {
					sums[index] = values.reduce((prev, curr) => {
					  const value = Number(curr);
					  if (!isNaN(value)) {
						return prev + curr;
					  } else {
						return prev;
					  }
					}, 0);
					sums[index] += ' 元';
				  } else {
					sums[index] = 'N/A';
				  }
				});
		
				return sums;
			},
			//获取当天日期
			getDate(type) {
				const date = new Date();
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				if (type === 'start') {
					year = year - 60;
				} else if (type === 'end') {
					year = year + 2;
				}
				month = month > 9 ? month : '0' + month;;
				day = day > 9 ? day : '0' + day;
				return `${year}-${month}-${day}`;
			},
		},
		created() {
			
		}
	}
</script>

<style>
	.bigDiv{font-family: 微软雅黑,"microsoft yahei";}
	.drawer{padding: 20px;}
	.d_header{display: flex;align-items: center;}
	.d_header h1{font-weight: 100;font-size: 25px;font-family: 微软雅黑;float: left;padding: 0;margin: 0;}
	.d_header span {color: #ccc;}
</style>
